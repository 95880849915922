<template>
  <v-row>
    <v-col
      :cols="12"
      :sm="6"
      height="300"
      v-for="(item, id) in filterProjects"
      :key="id"
    >
      <v-card
        class="bg-transparent"
        elevation="0"
        :to="{ name: 'project', params: { id: id, name: item.slug } }"
      >
        <v-img :src="item.image" height="300" />
      
        <v-card-title
          class="justify-center text-center"
          :class="[type === 'dark' ? 'white--text' : 'black--text']"
          ><span v-for="(item, id) in item.title" :key="id"
            >{{ item.copy }}&nbsp;
          </span></v-card-title
        >
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { projects } from "../data/projects.js";

export default {
  name: "ProjectGrid",
  data() {
    return {
      project: projects,
    };
  },
  props: {
    filter: Array,
    type: String,
  },
  computed: {
    filterProjects: function () {
      return this.project.filter((item) =>
        this.filter.some((fi) => item.category.includes(fi))
      );
    },
  },
};
</script>
