<template>
  <v-main>
    <v-header
      :headerImage="image"
      :displayOverlay="true"
      :overlayCopy="overlayCopy"
    />
    <container type="bg-dark" id="start">
      <title-and-description title="Photography &amp; Content">
        <p>
          Q Communications looks at clients’ business and communication objectives, 
          target audiences and stakeholders and builds great content in line with them.
        </p>
        <p>
          We have a number of in-house content specialists, including a Head of Content 
          and Head of Design, whose job it is to work with the team to create meaningful, 
          relevant, engaging and shareable content, to attract and engage customers, 
          building loyalty long term.
        </p>
        <p>
          However, even the best content in the world can sit unseen without a content 
          seeding plan. At Q Communications we don’t expect our audiences to find our content, 
          instead we reach out to them via the channels and the media they are consuming. 
          Whether that be social platforms, blogs, influencers, video sharing sites, search 
          engines or the more traditional newspapers and magazines.
        </p>
      </title-and-description>
      <hr />
      <services-list :serviceList="photographyServices" />
    </container>
    <container type="bg-light-image background-cover">
      <v-row>
        <v-col><h1 class="text-center">Some of Our Projects</h1></v-col>
      </v-row>
      <project-grid :filter="['Photography']" />
      <center>
        <v-btn tile large color="primary" class="my-10" to="/work"
          >View all of our work</v-btn
        >
      </center>
    </container>
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import ProjectGrid from "../components/ProjectGrid.vue";
import ServicesList from "../components/blocks/ServicesList.vue";

export default {
  name: "Photography",
  components: {
    vHeader,
    ProjectGrid,
    ServicesList,
  },
  data() {
    return {
      image: require("@/assets/backgrounds/photography_header.jpg"),
      overlayCopy: [
        {
          copy: "Photography and",
          size: "40",
        },
        {
          copy: "Content",
          size: "40",
        },
      ],
      photographyServices: [
        "Photography - Corporate and Lifestyle",
        "Story boards, Concepts and Casting",
        "Videography - Short and Corporate",
        "Graphic Design, Digital Animation and GIFs",
        "Social Media Photography",
        "Press Release Videos"
      ],
    };
  },
};
</script>
